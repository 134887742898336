<template>
  <transition name="sc-transition-fade" mode="out-in" appear>
    <div class="analytics-user-showcase-report mb-5 mx-md-5 mx-lg-auto">
      <h2 class="text-center mb-4 fw-normal">
        Group
        <strong v-if="group">{{ group.name }}</strong>
        <span v-if="group && group.deleted_at"
              class="text-muted" v-sc-tooltip="'Removed at ' + mainMxScDateFmt(group.deleted_at)">(removed)</span>
        <br/>using <strong>{{ scTitle }}</strong>
      </h2>

      <div ref="analyticsColumns" class="row"
           @vnode-mounted="analyticsColsMounted"
           @vnode-updated="analyticsColsUpdated"
           @vnode-unmounted="analyticsColsUnmounted">

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center">Summary</h5>
              <AnalyticsNoData :isLoading="groupShowcaseReportSummaryLoading"/>

              <ul v-if="!groupShowcaseReportSummaryLoading" class="list-group list-group-flush">
                <li class="list-group-item">
                  <div class="float-end">
                    <strong class="h4">{{ (groupShowcaseReportSummary.total_page_views) ? fmtInt(groupShowcaseReportSummary.total_page_views ) : 0 }}</strong>
                  </div>
                  Slide views
                </li>
                <li class="list-group-item">
                  <div class="float-end">
                    <strong class="h4">{{ (groupShowcaseReportSummary.total_resource_views) ?  fmtInt(groupShowcaseReportSummary.total_resource_views) : 0 }}</strong>
                  </div>
                  File views
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center">Top slides</h5>

              <AnalyticsNoData :isLoading="groupShowcaseReportSummaryLoading" />
              <AnalyticsTopSlides v-if="groupShowcaseReportSummary && !groupShowcaseReportSummaryLoading"
                                  :csvNameAddition="csvNameAddition"
                                  :topSlides="groupShowcaseReportSummary.page_views"
                                  :topSlidesTotalViews="groupShowcaseReportSummary.total_page_views"
                                  @paginated="paginated"></AnalyticsTopSlides>
            </div>
          </div>
        </div>

        <div class="analytics-grid-item col-12 col-lg-6 mb-3">
          <div class="card">
            <div class="card-body">
              <h5 class="card-title text-center">Top files</h5>

              <div class="d-flex justify-content-end">
                <div class="text-muted">File views</div>
              </div>

              <AnalyticsNoData :isLoading="groupShowcaseReportSummaryLoading" />
              <AnalyticsTopFiles v-if="!groupShowcaseReportSummaryLoading"
                                 :csvNameAddition="csvNameAddition"
                                 topFilesCountCsvTitle="Views"
                                 :topFiles="groupShowcaseReportSummary.resource_views"
                                 @paginated="paginated"></AnalyticsTopFiles>
            </div>
          </div>
        </div>
      </div>

    </div>
  </transition>
</template>

<script>
  import AnalyticsTopFiles from "./AnalyticsTopFiles.vue";
  import AnalyticsNoData from "./AnalyticsNoData.vue";
  import AnalyticsTopSlides from "./AnalyticsTopSlides.vue";
  import AnalyticsMixin from "./AnalyticsMixin";
  import MainAppMixin from "../../MainAppMixin";
  import $ from "jquery";
  import ScNotification from "../../../shared/common/ScNotification.vue";
  import Masonry from "masonry-layout";

  let _testNotEnoughData = false;
  let _dataManager = null;

  let DataManager = function() {
    return {
      getGroupShowcaseSummary(ajaxParams, vm) {
        if (vm.groupShowcaseReportSummaryLoading) return;
        vm.groupShowcaseReportSummary = {};
        vm.groupShowcaseReportSummaryLoading = true;

        $.ajax({
          url: "/main/analytics/ajax_get_group_showcase_report",
          data: ajaxParams

        }).done((data) => {
          if (!_testNotEnoughData || data)
            vm.groupShowcaseReportSummary = data;

        }).always(() => {
          vm.groupShowcaseReportSummaryLoading = false;

        }).fail((jqXhr) => {
          ScNotification.growlXhrError(jqXhr, 'loading group presentation summary');
        });
      }
    };

  };

  export default {
    name: "AnalyticsForGroupShowcase",
    mixins: [ AnalyticsMixin, MainAppMixin ],
    components: { AnalyticsTopSlides, AnalyticsTopFiles, AnalyticsNoData },
    props: {
      ajaxParams: {}
    },
    data () {
      return {
        groupShowcaseReportSummary: {},
        groupShowcaseReportSummaryLoading: false,
      };
    },
    mounted () {
      _dataManager = new DataManager();
      _dataManager.getGroupShowcaseSummary(this.ajaxParams, this);
    },
    methods: {
      analyticsColsMounted () {
        this.$msnry = new Masonry( this.$refs.analyticsColumns, {
          itemSelector: '.analytics-grid-item',
          percentPosition: true
        });
      },
      analyticsColsUpdated () {
        if (this.$msnry) {
          this.$msnry.reloadItems();
          this.$msnry.layout();
        }
      },
      analyticsColsUnmounted () {
        if (this.$msnry) {
          this.$msnry.destroy();
          this.$msnry = null;
        }
      },
      paginated () { // triggered masonry update after pagination
        this.$nextTick(() => {
          this.analyticsColsUpdated();
        });
      },
    },
    computed: {
      group() {
        return this.groupShowcaseReportSummary.group;
      },
      scTitle () {
        return this.groupShowcaseReportSummary.showcase_title;
      },
      csvNameAddition () {
        return (this.group && this.scTitle) ? this.group.name + ' ' + this.scTitle : '';
      }
    },
    watch: {
      ajaxParams () {
        _dataManager.getGroupShowcaseSummary(this.ajaxParams, this);
      }
    },
  }
</script>
