<template>
    <teleport to=".sc-modals-anchor">
    <div v-if="isModalShown" ref="scModal" v-vb-is:modal.show
         @vb-hidden-bs-modal="modalWasHidden"
         class="modal fade" tabindex="-1" >
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <button class="btn-close" data-bs-dismiss="modal"></button>
                    <h4 class="modal-title w-100 text-center">Manage Access</h4>
                </div>
                <div class="modal-body mb-3 clearfix" v-if="presentationId">

                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-11 offset-1">
                                <small class="d-block">
                                    <ScIcon name="questionCircle" class="text-muted"/> Changes made here may take up
                                    to 5 minutes to take effect. No republish necessary.
                                </small>
                            </div>
                        </div>
                    </div>

                    <ManageAccessViewPresentation :presentationId="presentationId"
                                              :workspaceId="mainMxCurrentWorkshopId"
                                              :limitedReporterAccess="showcaseHasReporterVisibilityAccessReduced"
                                              @changed-pres-access="changedPresAccess"
                                              @hide-manage-access="hideModal"></ManageAccessViewPresentation>

                    <ManageAccessEditPresentation :presentationId="presentationId"
                                              :workspaceId="mainMxCurrentWorkshopId"
                                              @changed-pres-access="changedPresAccess"
                                              @hide-manage-access="hideModal"></ManageAccessEditPresentation>

                    <ManageAutoUpdate v-if="!presentationIsReadOnly" :presentationId="presentationId"
                                            :workspaceId="mainMxCurrentWorkshopId"></ManageAutoUpdate>

                    <ManageViewerTags :presentationId="presentationId"
                                      :workspaceId="mainMxCurrentWorkshopId"></ManageViewerTags>

                    <ManageAccessPartnersList v-if="!presentationIsReadOnly" :presentationId="presentationId"
                                              :workspaceId="mainMxCurrentWorkshopId"
                                              @hide-manage-access="hideModal"></ManageAccessPartnersList>

                    <div class="container-fluid">
                        <hr class="my-4"/>
                        <div class="row">
                            <div class="col-11 offset-1 text-muted">
                                <small><ScIcon name="infoCircle"/> Transitions and arrows settings are now under "Settings" in the editor.</small>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
    </teleport>
</template>


<script>

    import $ from 'jquery'
    import ManageAutoUpdate from "./ManageAutoUpdate.vue";
    import ManageAccessViewPresentation from "./ManageAccessViewPresentation.vue";
    import ManageAccessEditPresentation from "./ManageAccessEditPresentation.vue";
    import ManageAccessPartnersList from "./ManageAccessPartnersList.vue";
    import MainAppMixin from "../../MainAppMixin";
    import ManageViewerTags from ".././ManageViewerTags.vue";
    import ScIcon from "../../../shared/common/ScIcon.vue";
    import ScNotification from "../../../shared/common/ScNotification.vue";

    let _ins = null;

    let DataManager = {
      determineIfShowcaseHasLimitedReporterVisibility(vm) {
        $.ajax({
          method: 'GET', url: "/main/users/ajax_get_showcase_belongs_in_limited_reporter_visibility",
          data: {
            workspace_id: vm.mainMxCurrentWorkshopId
          }
        }).done(function(data){
          vm.showcaseHasReporterVisibilityAccessReduced = data?.has_access ?? false;

        }).fail(function(jqXhr) {
          ScNotification.growlXhrError(jqXhr, "loading data");
        })
      }
    }

    export default {
        name: 'ManageAccessModal',
        mixins: [MainAppMixin],
        components: {ManageAccessViewPresentation, ManageAccessEditPresentation, ManageAutoUpdate,
            ManageAccessPartnersList, ManageViewerTags, ScIcon},
        props: {

        },
        data () {
            return {
                presentationId: null,
                presentationIsReadOnly: false,
                hasCallback: false,

                isModalShown: false,
                returnBool: false,
                resolvePromise: null,

                showcaseHasReporterVisibilityAccessReduced: false
            }
        },
        mounted () {
            _ins = this;
          DataManager.determineIfShowcaseHasLimitedReporterVisibility(this)
        },
        beforeUnmount () {
            _ins = null;
        },
        methods: {

            hideModal() {
                this.returnBool = true;
                this.$refs.scModal.$vb.modal.hide();
            },
            modalWasHidden() {
                this.isModalShown = false;
                if (this.returnBool && this.resolvePromise) this.resolvePromise();
            },
            // passing permissionType for PresentationPublish modal on workshop home
            changedPresAccess() {
                // if group access modal is open, we need to let it know data has changed
                if (this.hasCallback)
                    this.resolvePromise(true);
                else
                    this.resolvePromise(false);
            },
            toggleBsModal(options) {
                return new Promise((resolve) => {
                    //console.log('toggleBsModal', this.$refs, _ins.$el);
                    this.presentationId = options.presentation_id;
                    this.presentationIsReadOnly = options.presentation_read_only;

                    this.returnBool = false;
                    this.resolvePromise = resolve;
                    this.isModalShown = true;
                });
            }

        },

        //
        // public functions
        //
        modal (options) {
            return _ins.toggleBsModal(options)
        }

    };


</script>
